<template>
  <div>
    <div class="contents">
      <p class="title">个人资料</p>
      <el-divider></el-divider>
      <el-form label-position="top" :model="PIMData" :rules="rules" ref="form">
        <el-form-item label="账号">
          <el-input disabled class="input" v-model="PIMData.account"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="userName">
          <div style="display: flex;justify-content: left">
            <el-input class="input" v-model="PIMData.userName"></el-input>
            <!-- <el-button class="img"></el-button> -->
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleChange"
              accept=".jpg,.jpeg,.png"
              :file-list="fileList"
            >
              <img v-if="PIMData.avatar" :src="PIMData.avatar" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="imgTitle">点击传头像</span>
          </div>
        </el-form-item>
        <el-form-item label="所属组织">
          <el-input
            disabled
            class="input"
            v-model="PIMData.deptName"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色名称">
          <el-input
            disabled
            class="input"
            v-model="PIMData.roleName"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telPhone">
          <el-input class="input" v-model="PIMData.telPhone"></el-input>
        </el-form-item>
        <el-form-item label="座机号">
          <el-input class="input" v-model="PIMData.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input class="input" v-model="PIMData.email"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="buttons">
      <el-button class="button_cancel" @click="cancel">取消</el-button>
      <el-button class="button_preserve" @click="updateUsers">保存</el-button>
    </div>
  </div>
</template>

<script>
import { userQueryUserInfo, updateUser } from "@/api/systemAdmin/PIM.js";
import { userGetImage } from "@/api/AssetManagement";
export default {
  name: "accountAdmin",
  data() {
    return {
      PIMData: {
        account: "",
        userName: "",
        telPhone: "",
        phoneNum: "",
        email: "",
        avatar: "",
        roleName: ""
      },
      form: {
        account: "",
        userName: "",
        telPhone: "",
        phoneNum: "",
        email: "",
        avatar: ""
      },
      fileList: [],
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名称",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入数字字母下划线汉字!"));
              }
            },
            trigger: "change"
          }
        ],
        telPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正确的手机号!"));
              }
            },
            trigger: "change"
          }
        ],
        phoneNum: [
          {
            required: true,
            message: "请输入座机号",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (
                /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                  value
                )
              ) {
                callback();
              } else {
                callback(new Error("请输入正确的邮箱!"));
              }
            },
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {
    // this.getUsers()
  },
  created() {
    this.getUsers();
    userGetImage().then(({ data }) => {
      if (data.resultCode === 0) {
        this.PIMData.avatar = data.data;
        this.fileList.push({
          name: "",
          url: this.PIMData.avatar
        });
      }
    });
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    getUsers() {
      userQueryUserInfo(localStorage.getItem("userId")).then(res => {
        this.PIMData = res.data.data;
        this.fileList = [];
        this.PIMData.roleName = "";
        this.PIMData.avatar = res.data.data.imgUrl || "";
        if (this.PIMData.roleAndFns.length)
          this.PIMData.roleAndFns.forEach(value => {
            if (value.roleName) this.PIMData.roleName += value.roleName + " ";
          });
      });
    },
    updateUsers() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const formData = new FormData();
          Object.keys(this.form).forEach(key => {
            if (key !== "avatar") {
              formData.append(key, this.PIMData[key]);
            }
          });
          formData.append(
            "avatar",
            this.fileList[0] ? this.fileList[0].raw || "" : ""
          );

          updateUser(formData).then(res => {
            if (res.data.desc === "SUCCESS") {
              this.$message({
                showClose: true,
                message: "保存成功",
                type: "success"
              });
              this.$router.push({ path: "/dashboard/index" });
            } else {
              this.$message({
                showClose: true,
                message: "保存失败",
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList.splice(-1);
      this.PIMData.avatar = URL.createObjectURL(file.raw);
    }
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 20px;
}
.imgTitle {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #9cb1c0;
  letter-spacing: -0.04px;
  margin-left: 10px;
  line-height: 60px;
}
.img {
  width: 60px;
  height: 60px;
  margin-left: 15px;
  background: url("../../assets/images/img_upload.png");
  border: 0;
}
.contents {
  width: 97%;
  /*height: 700px;*/
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
}
.input {
  width: 500px;
  line-height: 60px;
}
/*    标题*/
.title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  letter-spacing: -0.05px;
}
/* 分割线*/
/deep/ .el-divider--horizontal {
  margin: 10px 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 12px;
  color: #8c939d;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #ddd;
  margin: 10px;
}
.avatar {
  width: 38px;
  height: 38px;
  display: block;
  margin: 10px;
}
</style>
