import axios from "../../utils/request";

//获取当前用户信息
export function getUser(data) {
  return axios({
    url: "/clodchainAM/web/user/getUser",
    method: "get",
    params: data
  });
}
//获取当前用户信息
export function userQueryUserInfo(userId) {
  return axios({
    url: `/clodchainAM/web/user/queryUserInfo/${userId}`,
    method: "get",
    params: {}
  });
}

//更新用户信息
export function updateUser(data) {
  return axios({
    url: "/clodchainAM/web/user/updateUser",
    method: "post",
    data: data
  });
}
//修改密码

export function amendPassword(data) {
  return axios({
    url: "/clodchainAM/web/user/resetPassword",
    method: "post",
    data: data
  });
}
